import React, { forwardRef, ForwardRefRenderFunction } from "react";

import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export type TypoProps = {
  tag: ETypoTag;
  variant: ETypoVariant;
  color?: ETypoColor;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const Typo: ForwardRefRenderFunction<any, TypoProps> = (
  { tag, variant, children, className, style, color = ETypoColor.TEXT },
  ref
) => {
  /**
   * Tag is a string that represents a valid HTML tag.
   * It is derived from the 'tag' property of the Typo component.
   */
  const Tag = tag as keyof JSX.IntrinsicElements;

  /**
   * computedClassName is a string that represents the CSS classes to be applied to the Typo component.
   * It is derived from the 'variant', 'className', and 'color' properties of the Typo component.
   */
  const computedClassName = cn({
    [variant]: true,
    [className as string]: !!className,
    [color]: true,
  });

  /**
   * The Typo component returns a JSX element with the specified tag, CSS classes, and inline styles.
   * The children of the Typo component are rendered within this JSX element.
   */
  return (
    <Tag ref={ref} className={`${variant} ${computedClassName}`} style={style}>
      {children}
    </Tag>
  );
};

export default forwardRef(Typo);
